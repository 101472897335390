import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Wer ist Jonas Feuß?`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Jonas Feuß`}</p>
    </blockquote>
    <p>{`Jonas Feuß ist eine dynamische und zukunftsorientierte Persönlichkeit, die sich durch eine einzigartige Mischung aus Kreativität und strategischem Denken auszeichnet. Mit einer klaren Vision und einem unerschütterlichen Willen meistert er Herausforderungen mit Bravour und hinterlässt dabei stets einen bleibenden Eindruck.`}</p>
    <p>{`Jonas verfügt über die Kompetenz, komplexe Probleme zu analysieren und innovative Lösungen zu entwickeln. Sein Fokus liegt auf der Generierung nachhaltiger Erfolge durch kontinuierliche Verbesserung mit echtem Mehrwert.`}</p>
    <p>{`Jonas ist für seinen inspirierenden Arbeitsstil bekannt. Er motiviert Teams und spornt sie zu herausragenden Leistungen an. Er legt Wert auf offene Kommunikation und fördert eine Kultur des Vertrauens und der Wertschätzung. Jonas behält stets den Überblick und erkennt Chancen und Optimierungspotenziale frühzeitig. Er arbeitet präzise, einfallsreich und mit höchsten Qualitätsansprüchen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      